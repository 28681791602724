<template lang="pug">
head-meta(title-page="Вход" )
the-login
</template>

<script>
import TheLogin from '../components/login/TheLogin'
import HeadMeta from '../components/_layout/HeadMeta'

export default {
  components: { HeadMeta, TheLogin },
  name: 'LoginPage',
  async beforeRouteEnter (to, from, next) {
    next()
  }
}
</script>
