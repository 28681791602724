<template lang='pug'>
.login
  .login__cont.cont
    h1.login__title.title Вход в кабинет
    form.login__form(@submit.prevent="sendForm")
      label.login__label Почта
        input.login__input.text.input(
            type="email",
            placeholder="mail@mail.ru",
            v-model="email",
            required,
            :class="{'input_wrong' : error}"
          )
      label.login__label Пароль
        input.login__input.text.input(
            type="password",
            placeholder="*************",
            v-model="password",
            required,
            :class="{'input_wrong' : error}"
          )
        span.login__error-text.text-error(v-if="error") Неверная почта или пароль
      .login__button-box
        button.login__button.button-orange.text(:disabled="disabledSubmitButton") Войти
      ul.login__list
        li.login__item
          router-link.login__link.link_gray(:to="{ name: 'ForgotPassword' }") Забыли пароль?
        li.login__item
          span.login__item-text Нет кабинета
          router-link.login__link.link_gray(:to= "{ name: 'Registration' }") Зарегистрируйтесь
</template>

<script>
import '@/assets/styles/components/login.sass'
import { login } from '../../assets/scripts/Api'

export default {
  name: 'TheLogin',
  data () {
    return {
      error: false,
      email: '',
      password: '',
      disabledSubmitButton: false
    }
  },
  methods: {
    sendForm () {
      this.error = false
      this.disabledSubmitButton = true
      login(this.email, this.password).then(async res => {
        if (res.status === 'error') this.error = true
        else await this.$router.push({ name: 'UserInfo' })
        this.disabledSubmitButton = false
      })
    }
  }
}
</script>
